import React, {useEffect, useRef, useState} from 'react'
import {Button, Layout} from 'antd'
import {fetchBackendTokenStream} from "../utils/backend"

const {Content} = Layout

const Dev: React.FC = () => {
    const [messages, setMessages] = useState('')
    const [messagesLoading, setMessagesLoading] = useState(false)
    const abortControllerRef = useRef<AbortController | null>(null) // Ref to store AbortController

    let content = ''

    async function getStart() {
        console.log('start')
        setMessagesLoading(true)
        abortControllerRef.current = new AbortController()

        try {
            for await (let chunk of fetchBackendTokenStream('get_dummy_stream',
                { signal: abortControllerRef.current.signal })) {
                content = content + chunk
                setMessages(content)
            }
        } catch (err) {
            if (err instanceof Error && err.name === 'AbortError') {
                console.log('Fetch aborted')
            } else {
                console.error('Fetch error:', err)
            }
        }

        setMessagesLoading(false)
    }

    function getStop() {
        setMessagesLoading(false)
        if (abortControllerRef.current) {
            abortControllerRef.current.abort()
        }
    }

    return (
        <Content
            className="content"
            style={{
                backgroundColor: 'transparent',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '10vh',
                width: '100vw',
                alignItems: 'center',
            }}
        >
            <div style={{width: '30vw'}}>
                <Button
                    type="primary"
                    style={{width: '100%'}}
                    onClick={getStart}
                >
                    start
                </Button>
                <p/>
                <Button
                    onClick={getStop}
                    style={{width: '100%'}}
                >
                    stop
                </Button>
                <p>
                    {messages}
                </p>
            </div>
        </Content>
    )
}

export default Dev
