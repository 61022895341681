import {fetchBackendToken} from './backend'
import {useEffect, useState} from 'react'


export function useSessionState(
    sessionStateRef: string = '',
    initSessionState: any = {ref: 'init'}
) {
    const [cleanSessionState, setCleanSessionState] = useState<any>(initSessionState)
    const [sessionState, setSessionState] = useState<any>(initSessionState)
    const [sessionStateLoading, setSessionStateLoading] = useState<boolean | string>('init')
    const sessionStateLoadingInit = sessionStateLoading === 'init'


    async function fetchSessionState() {
        setSessionStateLoading(true)
        const data = await fetchBackendToken(`/start_session_state?ref=${sessionStateRef}`)
        await syncSessionState(data)
    }

    async function syncSessionState(
        data: any
    ) {
        if (data) {
            if (JSON.stringify(data) !== JSON.stringify(sessionState)) {
                setSessionState(data)
                setCleanSessionState(data)
            }
            setSessionStateLoading(false)
        } else
            setSessionStateLoading('error')

    }

    async function postSessionState() {
        if (JSON.stringify(cleanSessionState) === JSON.stringify(sessionState))
            return
        console.log('postSessionState', sessionState)

        setSessionStateLoading(true)
        await fetchBackendToken(`set_session_state?ref=${sessionStateRef}`,
            {
                method: 'POST',
                body: JSON.stringify(sessionState)
            })

        const data = await fetchBackendToken(`get_session_state?ref=${sessionStateRef}`)
        await syncSessionState(data)
    }


    useEffect(() => {
        setSessionStateLoading('init')
    }, [sessionStateRef])


    useEffect(() => {
        if (sessionStateLoadingInit && sessionStateRef !== 'init') {
            fetchSessionState()
        } else if (sessionStateLoadingInit && sessionStateRef === 'init') {
            syncSessionState(initSessionState)
        }
    }, [sessionStateLoadingInit, sessionStateRef])


    useEffect(() => {
        if (!sessionStateLoadingInit && sessionStateRef !== 'init')
            postSessionState()

    }, [sessionState])

    return {sessionState, sessionStateLoading, setSessionState, setSessionStateLoading}
}