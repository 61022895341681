import React from 'react'
import {ReactComponent as MicrosoftLogo} from './microsoft_logo.svg'

interface MicrosoftIconProps {
    style?: React.CSSProperties
    className?: string
}

const MicrosoftIcon: React.FC<MicrosoftIconProps> = ({style, className}) => {
    return (
        <div style={style}>
            <MicrosoftLogo
                className={className}
                width='1em'
                height='1em'
            />
        </div>
    )
}

export default MicrosoftIcon
