import React, {useEffect, useRef, useState} from 'react'
import {BorderHorizontalOutlined, BorderLeftOutlined, BorderRightOutlined} from '@ant-design/icons'

import {Button, Checkbox, Layout, MenuProps, Select} from 'antd'
import {fetchBackendToken, fetchBackendTokenStream} from "./utils/backend"
import {useLocation, useNavigate} from "react-router-dom"
import TitleMedusa from "./icons/cupra.png"
import MedusaFootpage from "./icons/medusa_by_kraz.png"

import {CustomSider} from './components/LayoutWrapper'
import {ChatInput, ChatMessages} from './components/Chat'
import {useSessionState} from "./utils/session_state"
import {initScenario, initSessionState} from "./utils/session_state_init"
import SelectScenario from "./components/SelectScenario"

const {Content} = Layout

const displayOptions = [
    {
        label: <BorderRightOutlined/>,
        value: 'right'
    },
    {
        label: <BorderHorizontalOutlined/>,
        value: 'center'
    },
    {
        label: <BorderLeftOutlined/>,
        value: 'left'
    },
]


function useNavigateHome(page_link = '') {
    const location = useLocation()
    let page_title = location.pathname.substring(1)
    const navigate = useNavigate()
    console.log({page_title})

    useEffect(() => {
        if (page_title !== page_link) {
            console.log('redirecting')
            navigate(page_link)
        }
    }, [page_title])
}


const App: React.FC = () => {
    useNavigateHome()
    const title = 'Medusa'

    const [prompt, setPrompt] = useState<string>('')
    const [firstTime, setFirstTime] = useState(true)
    const [messagesLoading, setMessagesLoading] = useState(false)
    const abortControllerRef = useRef<AbortController | null>(null) // Ref to store AbortController
    const [messages, setMessages] = useState<any>([])

    const {
        sessionState, setSessionState,
        sessionStateLoading,
        setSessionStateLoading
    } = useSessionState('', initSessionState)

    const {scenario_key} = sessionState
    const {ref} = sessionState

    let tmpScenarioRef = 'init'
    if (sessionState.scenario_key)
        tmpScenarioRef = `/scenarios/${sessionState.scenario_key}`

    const {
        sessionState: scenario,
        setSessionState: setScenario,
        sessionStateLoading: scenarioLoading,
        setSessionStateLoading: setScenarioLoading
    } = useSessionState(tmpScenarioRef, initScenario)
    const {sampling} = scenario
    // const {messages} = scenario
    const {tablename} = scenario
    console.log({scenario})

    useEffect(() => {
        setMessages(scenario.messages)
    }, [scenario.messages])

    // console.log('length', scenario.messages.length)
    function setScenarioKeyNull() {
        console.log('setScenarioKeyNull')
        setSessionState((prevState: any) => ({
            ...prevState,
            scenario_key: null
        }))

        setScenario(
            {
                ...scenario,
                messages: [],
            }
        )
    }

    if (firstTime && sessionStateLoading === false) {
        console.log('firstTime')
        setFirstTime(false)
        setScenarioKeyNull()
    }


    const {tablenames} = sessionState


    //     label: <SettingOutlined style={{

    const paddingLeft: string = '5px'
    const items: MenuProps['items'] = [
        {
            label:
                <img
                    src={MedusaFootpage}
                    alt="Medusa_by_kraz"
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: paddingLeft,
                        marginBottom: '-30px',

                    }}
                />,
            key: 'footer'
        },
        {
            label:
                <img
                    src={TitleMedusa}
                    alt="Medusa"
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // marginLeft: '5px',
                        paddingLeft: paddingLeft,
                        marginBottom: '-30px',

                        // paddingRight: paddingLeft,
                    }}/>,
            key: 'home'
        },
        {
            label:
                <Button
                    onClick={setScenarioKeyNull}
                    type={'text'}
                    style={{
                        width: '100%',
                        display: 'flex',
                        // justifyContent: 'left',
                        alignItems: 'left',
                        // marginLeft: '5px',
                        paddingLeft: paddingLeft,

                    }}
                >New Chat</Button>,
            key: "new-chat",

        },
        {
            label:
                <div

                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        paddingLeft: paddingLeft,
                        paddingRight: paddingLeft,
                        alignItems: 'left',
                        justifyContent: 'left',

                    }
                    }
                >
                    <p
                        style={{
                            marginRight: '10px',
                        }}
                    >
                        Dataset:
                    </p>
                    <Select
                        value={tablename}
                        // style={{width: 120}}
                        onChange={(value) => {
                            setScenarioKeyNull()
                            setScenario(
                                {
                                    ...scenario,
                                    tablename: value
                                })

                        }}
                        options={
                            tablenames.map((tablename: string) => {
                                const tablename_label = tablename.replace('dataset_', '')
                                return {
                                    value: tablename,
                                    label: tablename_label
                                }
                            })}


                    />
                </div>,
            key: "dataset"
        },
        {
            label:
                <div

                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        paddingLeft: paddingLeft,
                        alignItems: 'center',

                    }
                    }
                >
                    <p
                        style={{
                            marginRight: '10px',
                        }}
                    >
                        Sampling:
                    </p>
                    <Checkbox
                        checked={sampling}
                        style={{
                            paddingLeft: paddingLeft,
                        }}
                        onChange={(value) => {
                            const checked = value.target.checked
                            setScenario(
                                {
                                    ...scenario,
                                    sampling: checked
                                })
                        }}

                    />
                </div>,
            key: "sampling",
        },
        {
            label: <div
                style={{
                    paddingLeft: paddingLeft,
                    paddingRight: paddingLeft,
                }}
            >
                <SelectScenario
                    scenarios={sessionState}
                    setScenariosLoading={setSessionStateLoading}
                    layout='vertical'


                />
            </div>,


            key: "chat-history",

        },


    ]

    async function addScenarioMessages(messages: any) {
        setMessagesLoading(false)
        setMessages(messages)
        const constrains = {
            ...scenario,
            messages: messages
        }
        await fetchBackendToken(`add_scenario?ref=${ref}&scenario_key=${scenario_key}`,
            {
                method: 'POST',
                body: JSON.stringify(constrains)
            })
        setSessionStateLoading('init')


    }


    async function sendMessage() {
        console.log('sendMessage')
        setMessagesLoading(true)

        const tmpPrompt = prompt
        setPrompt('') // restart prompt input
        if (prompt === '') {
            setMessagesLoading(false)
            return
        }

        let tmpMessages = messages
        tmpMessages = tmpMessages.concat(
            {role: 'user', content: tmpPrompt})
        let tmpMessages_ = tmpMessages.concat(
            {role: 'loading', content: ''})
        setMessages(tmpMessages_)

        let content = ''
        abortControllerRef.current = new AbortController()

        try {

            for await (
                let chunk of fetchBackendTokenStream(
                `predict_stream?sampling=${sampling}&tablename=${tablename}`,
                {
                    method: "post",
                    body: JSON.stringify(tmpMessages),
                    signal: abortControllerRef.current.signal
                }
            )) {
                content = content + chunk
                tmpMessages_ = tmpMessages.concat(
                    {role: 'assistant', content: content}
                )
                setMessages(tmpMessages_)
            }

        } catch (error) {
            // if (error instanceof Error && error.name === 'AbortError') {
            console.log('Fetch aborted')
            if (content === '')
                content = 'Model stopped'

            tmpMessages_ = tmpMessages.concat(
                {role: 'assistant', content: content})
            setMessagesLoading(false)
            setMessages(tmpMessages_)
            await addScenarioMessages(tmpMessages_)

        }


        await addScenarioMessages(tmpMessages_)

    }

    function stopMessage() {
        console.log('stopMessage')
        setMessagesLoading(false)
        if (abortControllerRef.current) {
            abortControllerRef.current.abort()
        }
    }

    return (
        <>

            <CustomSider items={items}/>

            <Content className="content"
                     style={{
                         backgroundColor: 'transparent',
                         height: '100vh',
                         display: 'flex',
                         flexDirection: 'column',
                         justifyContent: 'space-between',
                     }}>

                <div className="top"
                     style={{
                         overflow: 'auto',
                         alignItems: 'center',
                         display: 'flex',
                         flexDirection: 'column',
                     }}
                >

                    <ChatMessages
                        style={{
                            width: '50vw',

                        }}
                        messages={messages}/>

                </div>
                <ChatInput prompt={prompt} setPrompt={setPrompt} sendMessage={sendMessage}
                           stopMessage={stopMessage}
                           messagesLoading={messagesLoading}
                />


            </Content>
        </>
    )
}

export default App