export const initSessionState = {
    ref: 'init',
    scenario_key: null,
    scenarios_keys: [],
    tablenames: ['dataset_ncbs_dislikes']
}

export const initScenario = {
    ref: 'init',
    tablename: 'dataset_ncbs_dislikes',
    messages: [],
    sampling: false

}



