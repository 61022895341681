import React, {useState} from 'react'
import {Table, Typography} from 'antd'
import {getTableColumns} from '../utils/utils'

export const plotly_config = {
    displayModeBar: false
}

interface CustomTableProps {
    data: any
    ellipsis?: boolean
    style?: React.CSSProperties
    data_columns?: any
    // showModal?: React.Dispatch<React.SetStateAction<any>>
}

const Ellipsis: React.FC<any> = ({
                                     children,
                                     style
                                 }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <Typography.Paragraph
            style={{
                width: '10vw',
                ...style
            }}
            ellipsis={{
                // rows,
                expandable: 'collapsible',
                expanded,
                onExpand: (_, info) => setExpanded(info.expanded),
            }}
            // copyable
        >
            {children}
        </Typography.Paragraph>
    )

}

const CustomTable: React.FC<CustomTableProps> = (
    {
        data,
        data_columns,
        ellipsis = false,
        style,
    }) => {

    // const data = dataSource

    if (data && !data_columns) {
        data_columns = getTableColumns(data)

        if (ellipsis) {
            data_columns = data_columns.map((row: any) => {
                return {
                    ...row,
                    render: (text: any) => <
                        Ellipsis
                        style={style}
                    >{text}</Ellipsis>
                }
            })
        }

    }
    return (
        <Table
            size="small"
            dataSource={data}
            columns={data_columns}
            pagination={{showSizeChanger: true}}
            scroll={{x: 400}}
            bordered
            style={{
                border: "1px solid lightgreen",
                backgroundColor: "white",
                borderRadius: "5px",
                paddingRight: "1%",
                paddingLeft: "1%",
                marginBottom: "1%",
                marginTop: "2%",
                // height: "80vh",

            }}
        />

    )
}

export default CustomTable
