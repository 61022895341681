import {Button, Col, ConfigProvider, Layout, Menu, Row} from 'antd'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import './LayoutWrapper.css'
import Sider from 'antd/es/layout/Sider'
import {backgroundColor, colorPrimary, colorSecondary, fontFamily} from '../global'
import {onAuthStateChanged} from 'firebase/auth'
import {auth} from '../utils/firebase'

import {LogoutOutlined, UserOutlined, VerticalLeftOutlined, VerticalRightOutlined} from '@ant-design/icons'
import BottomLeftBackround from '../icons/cupra_logo.svg';
import {ErrorBoundary} from "react-error-boundary"
import MedusaFootpage from "../icons/medusa_by_kraz.png"

interface LayoutWrapperProps {
    component: React.ComponentType
}


const userItems = [
    {
        label:
            <Button
                shape='circle' icon={
                <UserOutlined
                    style={{fontSize: '15px'}}
                />
            }
                style={{height: '40px', width: '40px'}}
            />,

        key: 'logout-mail',
        children: [
            {
                label:
                    <div>
                        User configuration
                    </div>,
                key: 'mail'
            },
            {
                label:
                    <Link to='/login'>
                        <Button
                            style={{
                                borderColor: 'transparent'
                            }}
                            icon={<LogoutOutlined/>}
                        >
                            Log out
                        </Button>
                    </Link>,
                key: 'logout'
            }
        ]
    }
]

interface CustomSiderProps {
    items?: []

}

export const CustomSider: React.FC<any> = ({items = null}) => {
    const [showSider, setShowSider] = useState(true)
    let allItems: any[] = []
    if (items !== null) {
        allItems = allItems.concat(
            items)
    }

    return (
        <>
            {showSider ?
                <Sider
                    width='15%'
                    style={{
                        backgroundColor: 'white',
                        borderRightColor: colorSecondary,
                        borderRightWidth: '1px',
                        borderRightStyle: 'solid',
                    }}
                >
                    <div
                        style={{
                            height: '100vh',
                            backgroundColor: 'white'
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '60%'

                        }}
                        >

                            {allItems.map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        marginRight: '15px',
                                        marginLeft: '15px',
                                        margin: '5px'
                                    }}
                                >
                                    {item?.label}
                                </div>
                            ))}

                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'end',
                            height: '40%'

                        }}
                        >

                            <Row
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                }}
                            >
                                <Col span={12}
                                >
                                    <Menu
                                        mode='horizontal'

                                        items={userItems}
                                        style={{
                                            width: '100%',
                                            backgroundColor: 'transparent',
                                            marginBottom: '10px'
                                        }}
                                    />
                                </Col>
                                <Col span={12}
                                     style={
                                         {
                                             display: 'flex',
                                             justifyContent: 'flex-end',
                                             alignItems: 'center'
                                         }
                                     }
                                >
                                    <Button
                                        size={'large'}
                                        type={'text'}
                                        icon={<VerticalRightOutlined/>}
                                        onClick={() => setShowSider(false)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Sider> :
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        height: '99vh',
                        position: 'absolute',

                        // alignItems: 'flex-end'
                    }}>
                <Button

                        size={'large'}
                        type={'text'}
                        icon={
                            <VerticalLeftOutlined
                                style={{color: colorPrimary}}

                            />}
                        onClick={() => setShowSider(true)}
                    />
                </div>}
        </>

    )
}
const LayoutWrapper: React.FC<LayoutWrapperProps> = (
    {

        component: WrappedComponent
    }) => {
    const location = useLocation()
    let title = location.pathname.substring(1)
    if (title === '') {
        title = 'homepage'
    }
    console.log({title})
    const title_key = title.charAt(0).toUpperCase() + title.slice(1)

    const navigate = useNavigate()
    const [login, setLogin] = useState(false)
    console.log({login})
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user == null) {
                if (!['intro', 'plans', 'signup', 'login'].includes(title)) {
                    navigate('/login')
                }
            } else {
                if (['intro'].includes(title)) {
                    navigate('/')
                }
            }
            setLogin(true)
        })
    }, [auth, navigate, title])

    return (

        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: colorPrimary,
                    borderRadius: 4,
                    fontFamily: fontFamily
                }

            }}
        >
            <Layout
                className='layout content' style={{
                backgroundColor: backgroundColor,
                // backgroundImage: `url(${BottomLeftBackround})`,
                // backgroundSize: '50vw',
                // backgroundPosition: 'center',
                // backgroundRepeat: 'no-repeat',
                animation: 'fadeInAnimation 1s linear'
            }}
            >
                <ErrorBoundary fallback={<div>Something went wrong</div>}>
                    {login && <WrappedComponent/>}
                </ErrorBoundary>
            </Layout>
        </ConfigProvider>
    )
}

export default LayoutWrapper
