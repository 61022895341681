import {message} from 'antd'
import {auth} from './firebase'

declare global {
    interface Window {
        env: {
            BACKEND_URL?: string
        }
    }
}

class HTTPError extends Error {
    constructor(public response: Response) {
        super(`${response.status} ${response.statusText}`)
        this.name = 'HTTPError'
    }
}

const getBackendUrl = () => {
    const url = new URL(window.location.href)
    let newUrl = url.protocol + '//' + url.host

    if (url.protocol === 'http:') {
        newUrl = 'http://127.0.0.1:8000'
    } else {
        newUrl = newUrl + '/api'
    }

    console.log('backendUrl:', newUrl)
    return newUrl
}

const backendUrl = getBackendUrl()

export async function fetchBackend(
    endpoint: string,
    init: any = {},
    setLoading: any = () => {
    },
    return_response = false
) {
    setLoading(true)
    console.log('fetchBackend')
    try {
        console.log({backendUrl})
        const cleanEndpoint = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint
        const url = `${backendUrl}/${cleanEndpoint}`
        const url_verify_connection = `${backendUrl}/verify_connection`
        console.log({url})
        const newInit = {
            ...init,
            headers: {
                ...init.headers,
                frontend: 'true'
            }
        }

        const timeout = new Promise((resolve, reject) => {
            setTimeout(reject, 500, 'Can not connect to the backend server.')
        })

        await Promise.race([
            fetch(url_verify_connection),
            timeout
        ])
        const response = await fetch(
            url,
            newInit
        )

        if (!response.ok) {
            throw new HTTPError(response)
        }
        console.log('response', cleanEndpoint, {response})

        if (return_response)
            return response
        const data = await response.json()
        console.log('data', cleanEndpoint, {data})
        setLoading(false)
        return data
    } catch (error) {
        message.error('There has been a problem with the backend.')
        console.error(error)
        setLoading('error')
        return null
    }
}

export async function fetchBackendToken(
    endpoint: string,
    init: any = {},
    setLoading: any = () => {
    },
    return_response: boolean = false
) {
    if (!setLoading)
        setLoading = () => {
        }
    const token = await auth.currentUser?.getIdToken()
    if(!token) {
        message.error('Failed to get login token')
        setLoading('error')
        return null
    }
    // console.log({token})
    const newInit = {
        ...init,
        headers: {
            ...init.headers,
            token
        }
    }
    const data = await fetchBackend(endpoint, newInit, setLoading,
        return_response)
    return data
}

export async function* fetchBackendStream2(
    endpoint: string,
    init: any = {}) {
    const data = await fetchBackend(endpoint, init)
    yield data
}

export async function* fetchBackendTokenStream(
    endpoint: string,
    init: any = {}) {
    const response = await fetchBackendToken(endpoint, init, null, true)
    const reader = response.body.getReader()
    let result
    while (!(result = await reader.read()).done) {
        let chunk = new TextDecoder("utf-8").decode(result.value);
        yield chunk
    }
}


