import {Avatar, Button, Card, Col, Input, List, Row, Spin} from "antd"
import {HeatMapOutlined, MinusSquareFilled, SendOutlined, ThunderboltOutlined} from "@ant-design/icons"
import ReactMarkdown from "react-markdown"
import React, {useEffect, useRef} from "react"
import ExpandablePlot from "./ExpandablePlot"
import CustomTable from "./CustomTable"

const {TextArea} = Input;


interface ChatMessagesProps {
    messages: Array<any>,
    style?: any

}

export function isJsonString(str: string) {
    try {
        const out = JSON.parse(str)
        if (!out.hasOwnProperty('data')) {
            throw new Error("'data' key is not present in the parsed JSON object");
        }
    } catch (e) {
        return false
    }
    return true
}

export const ChatMessage: React.FC<{ message: any }> = (
    {message}) => {
    // console.log({message})
    let {content} = message

    if (message.role === 'tool') {
        content = JSON.parse(content)
        const {value} = content
        console.log({content})
        if (message.name === 'code_interpreter') {
            if (content.type === 'DataFrame') {
                return <CustomTable
                    data={value}
                    style={{width: '25vw'}}
                    ellipsis
                />
            } else if (content.type === 'Figure') {
                return <ExpandablePlot plotData={value}/>
            } else {
                return <div style={{color: 'black'}}>
                    <ReactMarkdown>
                        {value}
                    </ReactMarkdown>
                </div>
            }
        } else {
            return <div style={{color: 'black'}}>
                <ReactMarkdown>
                    {value}
                </ReactMarkdown>
            </div>
        }
    } else if (message.role === 'loading') {
        return <Spin size={'small'}/>
    } else {
        return (
            <div style={{color: 'black'}}>
                <ReactMarkdown>
                    {message.content}
                </ReactMarkdown>
            </div>
        )
    }
}

const default_message = `
¡Hola! Soy Medusa, tu asistente de IA.

¿Cómo puedo ayudarte con tu análisis de datos?
`


export const ChatMessages: React.FC<ChatMessagesProps> = (
    {messages, style}) => {
    messages = messages.map(
        (message) => {
            // if (message.role === 'assistant' && 'tool_calls' in message)
            //     message.content = 'Here is your answer'

            // if (message.role === 'system')
            //     return
            let title = message.role
            if (title == 'loading')
                title = 'assistant'
            return {
                ...message,
                title: title
            }
        }
    )

    messages = messages.filter(
        (message) => {
            const condition = message.role === 'system' ||
                message.role === 'tool' ||
                (message.role === 'tool' && message.name === '_continue') ||
                (message.role === 'assistant' && 'tool_calls' in message)
            // console.log({message, condition})
            return !condition


        }
    )

    const elementRef = useRef<null | HTMLDivElement>(null)

    function scrollToElement() {
        elementRef.current?.scrollIntoView({behavior: "smooth", block: "end"})
    }

    useEffect(scrollToElement, [messages])

    // console.log({messages})
    // console.log('length', messages.length, messages.length === 0)

    return (
        <div
            style={{
                // height: "80vh",
                overflow: 'auto',
                cursor: 'end',
                // width: '100%',
                marginTop: '2%',
                ...style
            }}>
            {messages.length === 0 ?
                <Card style={{width: '100%'}}
                      styles={{body: {backgroundColor: "white"}}}>
                    <Card.Meta
                        avatar={<Avatar
                            style={{
                                backgroundColor: 'lightgray',
                            }}
                            icon={
                                <HeatMapOutlined style={{
                                    color: 'white',
                                }}
                                />}/>}
                        title={'assistant'}
                        description={
                            <div style={{color: 'black'}}>
                                <ReactMarkdown>
                                    {default_message}
                                </ReactMarkdown>
                            </div>
                        }
                    />
                </Card> :
                <List
                    itemLayout="horizontal"
                    dataSource={messages}
                    renderItem={(message, index) => (

                        <List.Item key={`message_${index}`}>
                            <Card style={{
                                width: '100%',


                            }}
                                  styles={{body: {backgroundColor: message.role === 'user' ? '#f3f3f3ff' : "white"}}}>
                                <Card.Meta
                                    avatar={<Avatar
                                        style={{
                                            backgroundColor: message.role === 'user' ?
                                                'black' : message.role == 'assistant' ?
                                                    '#00b96b' :
                                                    message.role === 'tool' ?
                                                        'darkgreen' :
                                                        'lightgrey'
                                            ,
                                        }}
                                        icon={
                                            message.role === 'user' ?
                                                <ThunderboltOutlined/> :
                                                message.role === 'assistant' ?
                                                    <HeatMapOutlined style={{
                                                        color: 'white',

                                                    }}/> :
                                                    message.role === 'tool' ?
                                                        <HeatMapOutlined style={{
                                                            color: 'white',

                                                        }}/> :
                                                        <HeatMapOutlined/>
                                        }/>}
                                    title={message.title}
                                    description={

                                        <ChatMessage message={message}/>


                                    }
                                />
                            </Card>
                        </List.Item>
                    )}
                />}
            <div ref={elementRef}/>
            <p/>


        </div>
    )
}

interface ChatInputProps {
    prompt: string,
    setPrompt: (prompt: string) => void,
    sendMessage: () => void,
    stopMessage: () => void,
    messagesLoading?: boolean
}

export const ChatInput: React.FC<ChatInputProps> = (
    {
        prompt,
        setPrompt,
        sendMessage,
        stopMessage,
        messagesLoading = false,
    }
) => {
    return (
        <div className="bottom"
             style={{
                 // width: '100vw',
                 display: 'flex',
                 flexDirection: 'column',

                 alignItems: 'center',
             }}
        >

            <Row
                gutter={8}
                style={{
                    margin: '1%',

                }}
            >
                <Col>
                    < TextArea
                        style={{
                            borderColor: 'lightgreen',
                            width: '30vw',
                            padding: '2%',
                        }}
                        autoSize={{minRows: 1, maxRows: 6}}
                        placeholder="How can i help you?"
                        value={prompt}
                        onChange={e => {
                            if (e.target.value === '\n') {
                                setPrompt('')
                            } else {
                                setPrompt(e.target.value);
                            }

                        }}
                        onKeyDown={e => e.key === 'Enter' && !e.shiftKey && sendMessage()}
                    />
                </Col>
                <Col
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    {messagesLoading ?
                        <Button type="primary"
                                icon={<MinusSquareFilled/>}
                                style={{
                                    width: "4vw",
                                    height: "5vh",
                                    backgroundColor: 'black',

                                }}
                                onClick={stopMessage}
                        /> :
                        <Button type="primary"
                                icon={<SendOutlined/>}
                                style={{
                                    width: "4vw",
                                    height: "5vh",

                                }}
                                onClick={sendMessage}/>}

                </Col>
            </Row>
        </div>
    )
}