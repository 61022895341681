import React, {useEffect, useState} from 'react'
import Plotly from 'plotly.js-dist-min'
import {Button, Modal, Space} from 'antd'
import {DownloadOutlined, FullscreenOutlined} from '@ant-design/icons'
import Plot from 'react-plotly.js'
import {getTableColumns} from '../utils/utils'
import CustomTable from "./CustomTable"

export const plotly_config = {
    displayModeBar: false
}

interface ExpandablePlotProps {
    plotData: any
    style?: React.CSSProperties
    // showModal?: React.Dispatch<React.SetStateAction<any>>
}

const ExpandablePlot: React.FC<ExpandablePlotProps> = (
    {
        plotData, style,
        // showModal = () => {
        // },
    }) => {
    const [currentPlotData, setCurrentPlotData] = useState<any>(null)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

    function showModal(plotData: any) {
        setCurrentPlotData(plotData)
        setIsModalVisible(true)
    }

    console.log({plotData})
    if (typeof plotData === 'string')
        plotData = JSON.parse(plotData)
    console.log({plotData})
    const [hover, setHover] = useState(false)
    const [plotId, setPlotId] = useState<string>('')
    const [showTable, setShowTable] = useState(false)

    useEffect(() => {
        setPlotId('plotId-' + Math.random().toString(36).substr(2, 9))
    }, [])
    if (plotData == null) return null
    const downloadPlotAsImage = (plotId: string) => {
        const plotlyChart = document.getElementById(plotId)
        console.log('plotlyChart', plotlyChart)
        if (plotlyChart instanceof HTMLElement) {
            let filename = 'plot_'
            if (plotData.layout.title && typeof plotData.layout.title !== 'string') {
                if (plotData.layout.title.text) {
                    filename += plotData.layout.title.text.toLowerCase().replace(/ /g, '_')
                }
            }
            Plotly.downloadImage(plotlyChart,
                {
                    format: 'png',
                    filename,
                    width: 700,
                    height: 1000
                })
        } else {
            console.error('Unable to find the plot element.')
        }
    }
    const {raw_data} = plotData
    let raw_data_columns: any = {}
    if (raw_data)
        raw_data_columns = getTableColumns(raw_data)

    return (
        <>
            <div
                style={{
                    borderRadius: 4,
                    border: '0.1px solid',
                    borderColor: 'whitesmoke'
                    // width:'100%',
                    // margin: '10%',
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {hover &&
                    <>
                        <Space.Compact
                            style={{
                                position: 'absolute',
                                top: 30,
                                right: 10,
                                zIndex: 1001
                            }}
                        >
                            {/*<Button*/}
                            {/*    type={showTable ? 'primary' : 'default'}*/}
                            {/*    onClick={() => setShowTable(!showTable)}*/}
                            {/*    icon={<TableOutlined/>}*/}
                            {/*/>*/}
                            <Button
                                onClick={() => downloadPlotAsImage(plotId)}
                                icon={<DownloadOutlined/>}
                            />
                            <Button
                                onClick={() => showModal(plotData)}
                                icon={<FullscreenOutlined/>}
                            />

                        </Space.Compact>
                    </>}

                {showTable

                    ? <CustomTable
                        data={raw_data}
                    />
                    : <Plot
                        divId={plotId} data={plotData.data} layout={plotData.layout}
                        style={{
                            // width: '50vw',
                            // height: '100%'
                        }}
                        config={plotly_config}
                    />}

            </div>
            <Modal
                open={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                width='100%'
                footer={null}
                styles={{
                    body: {
                        height: '80vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }
                }}
            >
                {currentPlotData &&
                    <Plot
                        data={currentPlotData.data}
                        layout={{
                            ...currentPlotData.layout,
                            // autosize: true,
                            width: window.innerWidth * 0.8,
                            height: window.innerHeight * 0.8

                        }}
                        config={plotly_config}
                    />}
            </Modal>
        </>
    )
}

export default ExpandablePlot
