import {getAuth} from 'firebase/auth'
import {initializeApp} from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyD7yr0sr8jOLhqfIwJqZR-ll5rC4IkRLJQ",
  authDomain: "kraz-417715.firebaseapp.com",
  projectId: "kraz-417715",
  storageBucket: "kraz-417715.appspot.com",
  messagingSenderId: "451807618775",
  appId: "1:451807618775:web:1ab236dc6e39212638b292"
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
